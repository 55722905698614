.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 95vw;

  img {
    object-fit: cover;
  }
}

footer {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: center;
  text-align: center;

  a {
    text-decoration: none;
    color: #555;
  }
}
